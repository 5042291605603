import React from 'react'
import { graphql } from 'gatsby'

// Components
import SEO from 'components/shared/SEO'
import Layout from 'components/layout/Layout'
import FlexContentHandler from 'components/shared/FlexContentHandler'

interface PageTemplateProps {
  data: {
    page: GatsbyTypes.WpProject
  }
  location?: any
  prefix?: string
}

const PageTemplate: React.FC<PageTemplateProps> = ({
  data: {
    page: { flexContent, seo, projectType },
  },
  location = {},
  prefix = 'Project_Flexcontent',
}) => {
  const pType = projectType?.nodes![0]?.slug

  return (
    <Layout id={-1}>
      <SEO seo={seo} />
      <FlexContentHandler
        prefix={prefix}
        fields={flexContent}
        location={location}
        pType={pType}
      />
    </Layout>
  )
}

export const pageQuery = graphql`
  query projectById($pageId: String!) {
    page: wpProject(id: { eq: $pageId }) {
      ...generalProjectFragment
    }
  }
`

export default PageTemplate
